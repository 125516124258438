<template>
	<div class="social-icons">
		<a
			v-for="(val, key) in userSocialIcons"
			:key="`social-icon-${key}`"
			:href="socialObjects[key].href ? socialObjects[key].href + userSocialIcons[key] : val"
			class="social-icons__icon"
			target="_blank"
			v-show="val"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-html="socialObjects[key].image" alt="social icon" />
		</a>
	</div>
</template>

<script>
	export default {
		name: 'SocialIcons',
		props: {
			userSocialIcons: {
				default() {},
				type: Object,
			},
		},
		data() {
			return {
				socialObjects: {
					tiktok: {
						href: 'https://www.tiktok.com/@',
						image: '<path d="M22.81,5.78v4.09c-2.12,0-4.09-.68-5.7-1.82v8.34c0,4.17-3.38,7.56-7.56,7.56-1.56,0-3-.47-4.21-1.28-2.02-1.36-3.35-3.66-3.35-6.28,0-4.17,3.38-7.56,7.56-7.56,.35,0,.69,.02,1.04,.07v4.18c-.33-.1-.68-.16-1.05-.16-1.91,0-3.46,1.55-3.46,3.46,0,1.35,.77,2.52,1.9,3.09,.47,.24,1,.37,1.56,.37,1.91,0,3.45-1.54,3.46-3.44V.06h4.11V.58c.02,.16,.04,.31,.06,.47,.29,1.63,1.26,3.02,2.61,3.86,.91,.57,1.96,.87,3.03,.86Z"/>',
					},
					instagram: {
						href: 'https://www.instagram.com/',
						image: '<path d="M12.06,.06c-3.24,0-3.65,.01-4.92,.07-1.27,.06-2.14,.26-2.9,.55-.79,.3-1.45,.71-2.11,1.38-.66,.66-1.07,1.33-1.38,2.11-.3,.76-.5,1.63-.55,2.9-.06,1.27-.07,1.68-.07,4.92s.01,3.65,.07,4.92c.06,1.27,.26,2.14,.55,2.9,.31,.79,.71,1.45,1.38,2.11,.66,.66,1.33,1.07,2.11,1.38,.76,.3,1.63,.5,2.9,.55,1.27,.06,1.68,.07,4.92,.07s3.65-.01,4.92-.07c1.27-.06,2.14-.26,2.9-.55,.78-.3,1.45-.71,2.11-1.38,.66-.66,1.07-1.33,1.38-2.11,.29-.76,.49-1.63,.55-2.9,.06-1.27,.07-1.68,.07-4.92s-.01-3.65-.07-4.92c-.06-1.27-.26-2.14-.55-2.9-.31-.79-.71-1.45-1.38-2.11-.66-.66-1.33-1.07-2.11-1.38-.76-.3-1.63-.5-2.9-.55-1.27-.06-1.68-.07-4.92-.07h0Zm-1.07,2.15c.32,0,.67,0,1.07,0,3.19,0,3.56,.01,4.82,.07,1.16,.05,1.8,.25,2.22,.41,.56,.22,.95,.47,1.37,.89,.42,.42,.68,.82,.89,1.37,.16,.42,.36,1.05,.41,2.22,.06,1.26,.07,1.64,.07,4.82s-.01,3.56-.07,4.82c-.05,1.16-.25,1.8-.41,2.22-.22,.56-.48,.95-.89,1.37-.42,.42-.81,.68-1.37,.89-.42,.16-1.05,.36-2.22,.41-1.26,.06-1.64,.07-4.82,.07s-3.56-.01-4.82-.07c-1.16-.05-1.8-.25-2.22-.41-.56-.22-.95-.47-1.37-.89-.42-.42-.68-.81-.89-1.37-.16-.42-.36-1.05-.41-2.22-.06-1.26-.07-1.64-.07-4.82s.01-3.56,.07-4.82c.05-1.16,.25-1.8,.41-2.22,.22-.56,.48-.95,.89-1.37,.42-.42,.82-.68,1.37-.89,.42-.16,1.05-.36,2.22-.41,1.1-.05,1.53-.06,3.75-.07h0Zm7.44,1.98c-.79,0-1.43,.64-1.43,1.43s.64,1.43,1.43,1.43,1.43-.64,1.43-1.43-.64-1.43-1.43-1.43h0Zm-6.37,1.67c-3.38,0-6.13,2.74-6.13,6.13s2.74,6.13,6.13,6.13c3.38,0,6.13-2.74,6.13-6.13s-2.74-6.13-6.13-6.13h0Zm0,2.15c2.2,0,3.98,1.78,3.98,3.98s-1.78,3.98-3.98,3.98-3.98-1.78-3.98-3.98,1.78-3.98,3.98-3.98Z"/>',
					},
					youtube: {
						href: false,
						image: '<path d="M23.46,6.27c-.28-1.03-1.08-1.83-2.11-2.11-1.38-.53-14.74-.79-18.62,.02-1.03,.28-1.83,1.08-2.11,2.11C0,9.02-.04,14.92,.64,17.72c.28,1.03,1.08,1.83,2.11,2.11,2.73,.63,15.72,.72,18.62,0,1.03-.28,1.83-1.08,2.11-2.11,.66-2.98,.71-8.52-.02-11.45ZM9.76,15.57v-7.14l6.23,3.57-6.23,3.57Z"/>',
					},
					mail_to: {
						href: 'mailto:',
						image: '<path d="M23.2,3.71l-1.86,1.65-8.84,7.83c-.29,.26-.72,.26-1.01,0L2.66,5.36,.8,3.71c.46-.44,1.08-.71,1.76-.71H21.44c.68,0,1.3,.27,1.76,.71Z" style="fill-rule: evenodd;"/> \
		 							<path d="M24,5.56v12.88c0,1.41-1.15,2.56-2.56,2.56H2.56c-1.41,0-2.56-1.15-2.56-2.56V5.56l.15-.07L11.02,15.13c.56,.49,1.39,.49,1.95,0L23.85,5.49l.15,.07Z" style="fill-rule: evenodd;"/>',
					},
					twitter: {
						href: 'https://twitter.com/',
						image: '<path d="M21.6673 5.58376C20.9557 5.91416 20.1912 6.13941 19.3815 6.24678C20.1954 5.73408 20.8094 4.92734 21.0788 3.9712C20.3177 4.44551 19.4698 4.79382 18.559 4.98217C17.8006 4.18991 16.7404 3.69966 15.5877 3.70828C13.3692 3.72487 11.6297 5.57015 11.6987 7.8283C11.7086 8.14909 11.7513 8.46119 11.8309 8.76034C8.48771 8.61646 5.47718 7.00935 3.42347 4.54649C3.09776 5.15265 2.92027 5.85902 2.94311 6.60611C2.98647 8.02432 3.73803 9.27258 4.83861 9.99895C4.17463 9.98703 3.54695 9.80596 2.99847 9.50187L3.00002 9.55252C3.06067 11.5363 4.49611 13.1763 6.34457 13.5382C6.01153 13.6336 5.65646 13.6869 5.29156 13.6897C5.03447 13.6916 4.78075 13.6681 4.53454 13.6193C5.09427 15.2405 6.61491 16.4113 8.37412 16.4319C7.03513 17.5396 5.32183 18.2068 3.43925 18.2209C3.11581 18.2233 2.79587 18.2046 2.47956 18.1689C4.28606 19.3291 6.41736 19.9886 8.68144 19.9717C16.0749 19.9164 19.9232 13.652 19.7578 8.24512C19.7524 8.06784 19.7429 7.89059 19.7293 7.7176C20.4953 7.13348 21.1534 6.41085 21.6673 5.58376Z"/>',
					},
					twitch: {
						href: false,
						image: '<path d="M2.14,.06L.53,4.16V20.91H6.23v3.03h3.21l3.03-3.03h4.63l6.24-6.24V.06H2.14ZM21.2,13.6l-3.56,3.56h-5.7l-3.03,3.03v-3.03H4.1V2.2H21.2V13.6h0Zm-3.56-7.3v6.24h-2.14V6.3h2.14Zm-5.7,0v6.24h-2.14V6.3h2.14Z" style="fill-rule: evenodd;"/>',
					},
					discord: {
						href: false,
						image: '<path d="M20.22,4.4c-1.55-.72-3.2-1.25-4.92-1.55-.21,.38-.46,.9-.63,1.31-1.84-.28-3.66-.28-5.46,0-.17-.41-.42-.93-.64-1.31-1.73,.3-3.38,.83-4.93,1.55C.52,9.12-.32,13.71,.1,18.24c2.07,1.54,4.07,2.48,6.04,3.1,.49-.67,.92-1.38,1.29-2.13-.71-.27-1.39-.6-2.04-.99,.17-.13,.34-.26,.5-.4,3.93,1.84,8.2,1.84,12.08,0,.16,.14,.33,.27,.5,.4-.65,.39-1.33,.72-2.04,.99,.37,.75,.81,1.46,1.29,2.13,1.97-.61,3.98-1.55,6.04-3.1,.5-5.25-.85-9.8-3.55-13.83ZM7.97,15.45c-1.18,0-2.15-1.1-2.15-2.44s.95-2.44,2.15-2.44,2.17,1.1,2.15,2.44c0,1.34-.95,2.44-2.15,2.44Zm7.93,0c-1.18,0-2.15-1.1-2.15-2.44s.95-2.44,2.15-2.44,2.17,1.1,2.15,2.44c0,1.34-.95,2.44-2.15,2.44Z"/>',
					},
					etsy: {
						href: false,
						image: '<path d="M9 11.3V3.2c0-.3 0-.4.6-.4H16c1.1 0 1.7 1 2.1 3l.4 1.5h1.2c0-4.4.2-6.3.2-6.3s-2.8.3-4.4.3H3v1.1l1.4.4c1 .2 1.4.4 1.4 1.4a241.7 241.7 0 0 0 0 15.8c0 1-.3 1.2-1.4 1.5l-1.4.3V23l4.4-.1h7.4l5.5.1c0-1 .6-6 .7-6.5h-1l-1.1 2.7c-.9 2-2.2 2.2-3.5 2.2H11c-1.5 0-2-.6-2-2v-6.7h4c.8.2 1.3.4 1.6 1.5l.3 1.5h1.2l-.1-4 .2-3.8H15l-.4 1.8c-.3 1.2-.5 1.3-1.6 1.5l-4 .1Z" />',
					},
					facebook: {
						href: false,
						image: '<path d="M17.54,13.39l.66-4.29h-4.11v-2.78c0-1.17,.57-2.32,2.42-2.32h1.87V.36s-1.7-.29-3.32-.29c-3.39,0-5.6,2.05-5.6,5.77v3.27h-3.77v4.29h3.77v10.36c.75,.12,1.53,.18,2.32,.18s1.56-.06,2.32-.18V13.39h3.46Z"/>',
					},
					link: {
						href: false,
						image: '<g clip-path="url(#a)" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.8 2.7a6.7 6.7 0 1 1 9.5 9.5l-1 1a1.1 1.1 0 0 1-1.5-1.6l1-1a4.5 4.5 0 1 0-6.4-6.3l-2 2a4.5 4.5 0 0 0 0 6.3A1.1 1.1 0 0 1 10 14a6.7 6.7 0 0 1 0-9.4l2-2Z"/><path d="M12.4 21A6.7 6.7 0 1 1 3 11.6l1-1a1.1 1.1 0 0 1 1.5 1.6l-1 1a4.5 4.5 0 0 0 6.4 6.3l2-2a4.5 4.5 0 0 0 0-6.3 1.1 1.1 0 0 1 1.5-1.6 6.7 6.7 0 0 1 0 9.5l-2 2Z"/></g><defs><clipPath id="a"><path d="M0 0H24V24H0z"/></clipPath></defs>',
					},
					snapchat: {
						href: false,
						image: '<path d="M12.14,23.19c-.07,0-.13,0-.18,0-.04,0-.08,0-.12,0-1.42,0-2.37-.67-3.21-1.26-.6-.42-1.17-.82-1.83-.93-.33-.05-.65-.08-.96-.08-.56,0-1.01,.09-1.34,.15-.2,.04-.37,.07-.51,.07s-.31-.03-.38-.27c-.06-.19-.1-.38-.14-.55-.1-.44-.17-.72-.33-.74-1.78-.27-2.83-.68-3.04-1.17-.02-.05-.03-.1-.04-.15,0-.15,.09-.27,.24-.3,1.41-.23,2.67-.98,3.74-2.22,.83-.96,1.23-1.88,1.27-1.98,0,0,0,0,0-.01,.21-.42,.25-.78,.12-1.07-.23-.54-.99-.78-1.49-.94-.12-.04-.24-.08-.34-.11-.45-.18-1.18-.55-1.08-1.06,.07-.37,.57-.63,.97-.63,.11,0,.21,.02,.29,.06,.45,.21,.86,.32,1.21,.32,.43,0,.64-.17,.7-.21-.01-.23-.03-.48-.04-.71,0,0,0,0,0,0-.1-1.62-.23-3.65,.29-4.8C7.49,1.09,10.78,.82,11.74,.82c.03,0,.42,0,.42,0,.02,0,.04,0,.06,0,.97,0,4.26,.27,5.81,3.74,.52,1.16,.39,3.18,.29,4.81v.08c-.02,.22-.03,.43-.04,.63,.05,.04,.24,.2,.63,.21,.33-.01,.72-.12,1.14-.32,.13-.06,.27-.07,.37-.07,.15,0,.3,.03,.42,.08h0c.36,.13,.59,.38,.6,.65,0,.25-.18,.62-1.09,.98-.09,.04-.21,.07-.34,.11-.5,.16-1.26,.4-1.49,.94-.12,.29-.08,.65,.12,1.07,0,0,0,0,0,.01,.06,.15,1.59,3.64,5.01,4.2,.14,.02,.25,.15,.24,.3,0,.05-.02,.1-.04,.16-.21,.49-1.26,.89-3.04,1.17-.17,.03-.24,.3-.33,.74-.04,.18-.08,.36-.14,.55-.05,.18-.17,.26-.35,.26h-.03c-.12,0-.29-.02-.51-.06-.38-.07-.8-.14-1.34-.14-.31,0-.64,.03-.96,.08-.66,.11-1.23,.51-1.83,.93-.84,.59-1.79,1.26-3.21,1.26"/>',
					},
					linkedin: {
						href: false,
						image: '<rect x="1.48" y="8.05" width="4.63" height="13.93"/> \
		 							<path d="M6.42,3.74c0,1.33-1.01,2.41-2.62,2.41h-.03c-1.55,0-2.56-1.07-2.56-2.41S2.24,1.33,3.83,1.33s2.56,1.04,2.59,2.41Z" style="fill-rule: evenodd;"/> \
		 							<path d="M22.8,13.99v7.99h-4.63v-7.45c0-1.87-.67-3.15-2.35-3.15-1.28,0-2.04,.86-2.37,1.69-.12,.3-.15,.71-.15,1.13v7.78h-4.63s.06-12.62,0-13.93h4.63v1.97s-.02,.03-.03,.05h.03v-.05c.61-.95,1.71-2.3,4.17-2.3,3.05,0,5.33,1.99,5.33,6.27Z" style="fill-rule: evenodd;"/>',
					},
					pinterest: {
						href: false,
						image: '<g clip-path="url(#a)"><path d="M12 0a12 12 0 0 0-4.4 23.2c0-1-.2-2.4 0-3.5l1.5-6s-.4-.7-.4-1.7c0-1.7 1-3 2.2-3 1 0 1.5.8 1.5 1.8s-.6 2.5-1 4c-.3 1.2.6 2.1 1.8 2.1 2.1 0 3.8-2.2 3.8-5.5 0-2.8-2.1-4.9-5-4.9a5.2 5.2 0 0 0-5.5 5.3c0 1 .4 2 1 2.7v.3l-.3 1.4c0 .2-.2.3-.4.2-1.5-.7-2.5-3-2.5-4.7 0-3.8 2.8-7.3 8-7.3 4.1 0 7.4 3 7.4 7 0 4.1-2.6 7.4-6.3 7.4-1.2 0-2.3-.6-2.7-1.3l-.8 2.8c-.2 1-1 2.4-1.5 3.2A12 12 0 1 0 12 0Z"/></g><defs><clipPath id="a"><path d="M0 0H24V24H0z"/></clipPath></defs>',
					},
					vimeo: {
						href: false,
						image: '<path id="path4" d="M23.99,6.43c-.11,2.33-1.73,5.52-4.88,9.56-3.25,4.23-6,6.34-8.25,6.34-1.39,0-2.57-1.29-3.54-3.86-.64-2.36-1.29-4.72-1.93-7.08-.72-2.57-1.48-3.86-2.3-3.86-.18,0-.81,.38-1.88,1.13L.09,7.21c1.18-1.04,2.34-2.07,3.49-3.11,1.57-1.36,2.76-2.08,3.54-2.15,1.86-.18,3.01,1.09,3.44,3.82,.46,2.94,.79,4.77,.97,5.48,.54,2.44,1.13,3.66,1.77,3.66,.5,0,1.25-.79,2.26-2.37,1-1.58,1.54-2.79,1.61-3.61,.14-1.37-.39-2.05-1.61-2.05-.57,0-1.16,.13-1.77,.39,1.17-3.85,3.42-5.72,6.74-5.62,2.46,.07,3.62,1.67,3.47,4.78"/>',
					},
				},
			}
		},
	}
</script>
